.hover-underline:hover {
  text-decoration: underline;
}

.underline {
  text-decoration: underline;
}

.color-blue {
  color: #2e69bd;
}

.bold {
  font-weight: bold;
}

.payload-hover:hover {
  opacity: 0.8;
}

.circle-styles {
  display: flex;
  // width: 10%;
  //   height: 100%;
  width: 6vw;
  height: 15vh;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  position: relative;
}
// .circle-styles::before {
//   content: "";
//   padding-right: 100%;
// }

#resize {
  // resize: both;
  // overflow: auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ratio {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  aspect-ratio: 1/1;
}
.ratio.vertical,
.ratio.vertical > canvas {
  height: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
// .ratio.horizontal,
// .ratio.horizontal > canvas {
//   width: 100%;
//   max-height: 100%;
//   border: 1px solid yellow;
//   border-radius: 50%;
// }
.ratio > div {
  // position: absolute;
  // position: relative;
  // top: 50%;
  // right: 50%;
  // bottom: 0;
  // left: 50%;
  // border-radius: 50%;
  // border: 1px solid white;
}
.contents {
  // background: linear-gradient(to right, gray, black, gray);
}

.table-borderless {
  border: none !important;
  height: "100%";
}

.table-accordion-panel {
  padding: 0 !important;
  // border: none !important;
  background-color: #232529;
  div {
    max-height: 0px;
    visibility: hidden;
    // transition: visibility max-height 100ms ease;
    // p {
    //   padding: 0.78571429em 0.78571429em;
    // }
  }
}

.selectable-item {
  cursor: pointer;
}

.color-disabled {
  color: grey !important;
}

.icon-container {
  display: inline-block;
  transition: 100ms ease-out;
  // transform: scale(1.5);
}

.icon-container > i {
  margin: 0 !important;
}

.rotate-dropdown-down {
  transform: rotate(180deg);
}

.rotate-dropdown-up {
  transform: rotate(0deg);
}

.custom-table-header {
  background: #1f2126;
}

.table-bottom-underline {
  border-bottom-color: rgba(200, 200, 200, 0.55) !important;
  border-bottom-width: 1px !important;
}

.picker {
  position: relative;
}

.swatch {
  width: 25px;
  height: 25px;
  border: 2px solid #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.popover {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  border-radius: 9px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.action-progress {
  margin-bottom: 0 !important;
  width: 250px !important;
  font-weight: bold !important;
}

.table-border-right {
  border-right: 1px solid #78797b !important;
}

.table-border-bottom {
  border-bottom: 1px solid #78797b !important;
}

.table-border-left-none {
  border-left-width: 0px !important;
}

.table-border-bottom-none {
  border-bottom-width: 0px !important;
}

.action-progress-item {
  position: relative;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.initiated {
    // background-color: #2e69bd;
    background-color: #6d95d2; // new color scheme
  }

  &.completed {
    // background-color: #1fba8b;
    background-color: #609d5a; // new color scheme
  }

  &.failed {
    // background-color: #fb6747;
    background-color: #c53f4f; // new color scheme
  }

  &.queued {
    // background-color: #808080;
    background-color: #808080; // new color scheme
  }

  &.in-progress {
    // background-color: #ffff67;
    background-color: #9e9335; // new color scheme
  }
}

.action-progress-pulsating-circle {
  position: absolute;
  width: 50px;
  height: 50px;

  &:before {
    content: "";
    position: relative;
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    margin-left: -100%;
    margin-top: -100%;
    border-radius: 50%;
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-iteration-count: 2;
    animation-fill-mode: forwards;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    // animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
  }

  &.initiated {
    &:after {
      // background-color: #2e69bd;
      background-color: #6d95d2; // new color scheme
    }
    &:before {
      // background-color: #2e69bd;
      background-color: #6d95d2; // new color scheme
    }
  }

  &.completed {
    &:after {
      // background-color: #1fba8b;
      background-color: #609d5a; // new color scheme
    }
    &:before {
      // background-color: #1fba8b;
      background-color: #609d5a; // new color scheme
    }
  }

  &.failed {
    &:after {
      // background-color: #fb6747;
      background-color: #c53f4f; // new color scheme
    }
    &:before {
      // background-color: #fb6747;
      background-color: #c53f4f; // new color scheme
    }
  }

  &.queued {
    &:after {
      // background-color: #808080;
      background-color: #808080; // new color scheme
    }
    &:before {
      // background-color: #808080;
      background-color: #808080; // new color scheme
    }
  }

  &.in-progress {
    &:after {
      // background-color: #ffff67;
      background-color: #9e9335; // new color scheme
    }
    &:before {
      // background-color: #ffff67;
      background-color: #9e9335; // new color scheme
    }
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

.action-progress-connector {
  height: 5px;
  width: 150px;

  &.active {
    // background-color: #1fba8b;
    background-color: #609d5a; // new color scheme
  }

  &.inactive {
    background-color: #9c9999;
  }

  &.failed {
    // background-color: #fb6747;
    background-color: #c53f4f; // new color scheme
  }
}

.min-width-150 {
  min-width: 150px;
}

.actions-summary-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5rem;
  min-width: 800px;

  @media (max-width: 1320px) {
    padding: 0;
  }
}

.action-progress-circle-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.action-legend {
  margin-left: 7rem;

  @media (max-width: 1320px) {
    margin-left: 0rem;
  }
}

.ui.attached.segment.borderNone {
  border: none !important;
}

.sendfile-form {
  padding: 1rem 8rem !important;
}

.progress-margin {
  margin-left: 4rem !important;
}
